import Vue from "vue";
import Vuex from "vuex";

import { TokenService } from "@/services/storage.service";
import { UserService } from "@/services/user.service";
import ApiService from "@/services/api.service";
import router from "@/router/index";
import Swal from "sweetalert2";
Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  accessToken: TokenService.getToken() || null,
  otpHash: TokenService.getOtpHash() || null,
  state_true: "Joes",
  status: "",
  authenticationError: "",
  authenticationErrorCode: false,
  authenticating: false,
  userInstance: JSON.parse(TokenService.getUser()) || {},
  userLoader: false,
  userUpdatePrompt: false,
  loading: false,
  verifyOtpLoading: false,
  changePasswordToken: "",
  allStaffs: null,
  reportsLoading: false,

  transactionObject: {},
};
const actions = {
  loginUser: async function(context, params) {
    //commit user state
    context.commit("setLoading", true);
    context.commit("loginRequest");
    context.commit("clearError");
    try {
      const loginUser = await UserService.login(
        params.username,
        params.password
      );
      console.log(loginUser);
      context.commit("setLoading", false);
      context.commit("loginSuccess");
      context.commit("setAccessToken", loginUser.auth_token);
      context.commit("setUser", loginUser);
      context.state.loginLoader = false;
      ApiService.setHeader();

      if (loginUser.pin_setup === "no") {
        await router.replace({ name: "Settings" });
      } else {
        await router.push({ name: "Dashboard" });
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Signed in successfully",
      });
    } catch (e) {
      console.log(e);
      console.log(e.response);
      context.commit("setLoading", false);
      let error = e.response.data;
      console.log(error.response_message);
      let errorBag = {
        code: error.response_code,
        message: error.response_message,
        data: error.data,
      };
      console.log(errorBag);
      Swal.fire({
        title: "Error!",
        text: error.response_message,
        icon: "error",
        confirmButtonText: "Close",
      });
      context.commit("setLoading", false);
      context.commit("loginError", errorBag);
      throw errorBag;
    }
  },
  logoutUser: async (context) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const log = UserService.logout();
      context.commit("logoutRequest");
      router.push({ name: "Login" });
    } catch (error) {
      console.log(error);
      // let _error = error.response.data
      // ErrorService.displayErrorAlert(_error)
      // // let error = error.response.data;
      // // let errorBag = {
      // //     code : error.response_code,
      // //     message : error.response_message,
      // //     data : error.data,
      // // }
      // // throw(errorBag)
    }
  },
  updateUser: async (context, payload) => {
    context.state.userUpdatePrompt = false;
    context.commit("setLoading", true);

    context.commit("logUserUpdate", true);
    try {
      // context.commit('logUserUpdate')
      const updateUser = UserService.updateUser(payload);
      updateUser.then((res) => {
        console.log(res);
        context.state.userUpdatePrompt = true;
        context.commit("setLoading", false);

        context.commit("logUser", res.data);
        context.commit("logUserUpdate", false);
      });
    } catch (error) {
      // context.commit('logUserUpdate')
    }
  },
  getCustomerWallet: async (context) => {
    try {
      const response = await ApiService.get("/user/customer-wallet");
      console.log(response);
      context.commit("setWalletBalance", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  generateApiKey: async (context) => {
    context.commit("setLoading", true);

    try {
      const response = await ApiService.get("/partners/generate-api-keys");
      console.log(response);
      let user = response.data.data;
      context.commit("setLoading", false);
      context.commit("setUser", user);
    } catch (error) {
      context.commit("setLoading", false);
      console.log(error);
      // context.commit('setUser', error.response.data.data)
    }
  },
};
const getters = {
  reportsLoading: (state) => state.reportsLoading,
  isLoggedIn: (state) => {
    return state.accessToken ? true : false;
  },
  authenticating: (state) => {
    return state.authenticating;
  },
  authenticationError: (state) => {
    return state.authenticationError;
  },
  userObj: (state) => {
    return state.userInstance;
  },
  userLoader: (state) => {
    return state.userLoader;
  },
  loading: (state) => {
    return state.loading;
  },
  verifyOtpLoading: (state) => {
    return state.verifyOtpLoading;
  },
  getOtpHash: (state) => {
    return state.otpHash;
  },
  transactionObject: (state) => state.transactionObject,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = "";
    state.authenticationErrorCode = 0;
  },
  loginSuccess: (state) => {
    state.authenticating = false;
  },
  loginError(state, errorBag) {
    state.authenticating = false;
    state.authenticationErrorCode = true;
    state.authenticationError = errorBag;
  },
  logUser(state, payload) {
    state.userInstance = payload;
  },
  logUserUpdate(state, value) {
    state.userLoader = value;
  },
  setWalletBalance(state, value) {
    state.userInstance.customer_balance = value;
    TokenService.saveUser(JSON.stringify(state.userInstance));
  },
  logoutRequest(state) {
    state.userInstance = {};
    state.accessToken = "";
  },
  registerRequest(state) {
    state.status = "loading";
  },
  registerError(state, errorBag) {
    state.authenticating = false;
    state.authenticationErrorCode = true;
    state.authenticationError = errorBag;
  },
  setAccessToken(state, accessToken) {
    TokenService.saveToken(accessToken);
    state.accessToken = accessToken;
  },
  setOtpHash(state, payload) {
    TokenService.saveOtpHash(payload);
    state.otpHash = payload;
  },
  setUser(state, user) {
    TokenService.saveUser(JSON.stringify(user));
    state.userInstance = user;
  },
  setChangePasswordToken(state, payload) {
    state.changePasswordToken = payload;
  },
  clearError(state) {
    state.authenticationError = null;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setVerifyOtpLoading(state, payload) {
    state.verifyOtpLoading = payload;
  },
  setCreateTransactionObject(state, payload) {
    state.transactionObject = payload;
  },
  setReportsLoading(state, payload) {
    state.reportsLoading = payload;
  },

  // setStep1FormResult(state, payload) {
  //     state.step1FormResult = payload
  // },
  // setStep2FormResult(state, payload) {
  //     state.step2FormResult = payload
  // },
  // setStep3FormResult(state, payload) {
  //     state.step3FormResult = payload
  // },
  // // setStep3FormResult(state, payload) {
  // //     state.step3FormResult = payload
  // // },
  // setStep4FormResult(state, payload) {
  //     state.step4FormResult = payload
  // },
  // setStep5FormResult(state, payload) {
  //     state.step5FormResult = payload
  // },
  // setAttachments(state, payload) {
  //     state.attachments = payload
  // },
  // setBranch(state, payload) {
  //     state.branch = payload
  // },
  // setSubmitFormData(state, payload) {
  //     state.submitFormData = payload
  // }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
