import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import VueSweetalert2 from "vue-sweetalert2";
import { iconsSet as icons } from "./assets/icons/icons.js";
import ApiService from "./services/api.service";
import { TokenService } from "./services/storage.service";
import store from "./store";
import Flutterwave from "vue-flutterwave";
import SmartTable from "vuejs-smart-table";
import jQuery from "jquery";

Vue.use(VueSweetalert2);
Vue.use(SmartTable);
Vue.use(Flutterwave, {
  publicKey: "FLWPUBK-3fea9a738b7e6a0d914c46e571685f5e-X",
});

import CLoader from "@/components/loaders/CLoader";
import AppSpinner from "@/components/loaders/AppSpinner";

ApiService.init("https://server.onecentral.ng/api");
//ApiService.setHeader();

//if the token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader();
}

//global use
Vue.component("loader-icon", CLoader);
Vue.component("app-spinner", AppSpinner);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

//use jquery globally
window.$ = window.jQuery = jQuery;

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
