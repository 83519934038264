/* eslint-disable no-useless-catch */
import ApiService from "./api.service";
import { TokenService } from "./storage.service";

class AuthencationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to Token Service
   *
   * @returns access_token
   * @throws AuthenticationError
   */

  login: async function(username, password) {
    const requestData = {
      method: "post",
      url: "/partners/login",
      data: {
        username: username,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      //TokenService.saveToken(response.data.data.auth_token)
      //ApiService.setHeader();
      //console.log(response)
      //console.log(response.data)
      console.log(response.data);
      ///console.log(response.data.data)
      return response.data.body;
    } catch (error) {
      throw error;
    }
  },
  generateLoginOtp: async function(unique_hash) {
    const requestData = {
      method: "post",
      url: "backend/auth/generate-otp",
      data: {
        unique_hash: unique_hash,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log(response);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
  verifyLoginOtp: async function(unique_hash, otp) {
    const requestData = {
      method: "post",
      url: "backend/auth/verify-login-otp",
      data: {
        unique_hash: unique_hash,
        otp: otp,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      TokenService.saveToken(response.data.data.auth_token);
      // TokenService.saveRefreshToken(response.data.refresh_token)
      // Update the header in ApiService
      ApiService.setHeader();
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async function(email) {
    const data = {
      email: email,
    };

    try {
      const response = await ApiService.post("/forget-password", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  verifyResetPasswordOTP: async function(payload) {
    const requestData = {
      method: "post",
      url: "/validate-otp",
      data: {
        type: payload.type,
        email: payload.email,
        otp: payload.otp,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  finalizeResetPassword: async function(payload) {
    let requestData = {
      method: "post",
      url: "/reset-password",
      data: {
        token: payload.token,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    TokenService.removeToken();
    TokenService.removeOtpHash();
    TokenService.removeUser();
    ApiService.removeHeader();
  },
  getUser: async function() {
    try {
      const response = await ApiService.get("/user");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async function(payload) {
    try {
      const response = await ApiService.post("/user/update-profile", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export { UserService, AuthencationError };
