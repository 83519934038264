// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = [
  "556 134",
  `
  <g>
    <g style="fill:#1bbd93;">
      <svg width="110" height="120" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.7532 12.3113C25.7532 18.9111 20.1001 24.2613 13.1266 24.2613C6.15312 24.2613 0.5 18.9111 0.5 12.3113C0.5 5.71153 6.15312 0.361328 13.1266 0.361328C20.1001 0.361328 25.7532 5.71153 25.7532 12.3113ZM4.06587 12.3113C4.06587 17.0473 8.1225 20.8865 13.1266 20.8865C18.1307 20.8865 22.1873 17.0473 22.1873 12.3113C22.1873 7.57537 18.1307 3.73612 13.1266 3.73612C8.1225 3.73612 4.06587 7.57537 4.06587 12.3113Z" fill="#5296CA"/>
      <path d="M20.2668 12.2665C20.2668 16.0165 17.0547 19.0565 13.0924 19.0565C9.13007 19.0565 5.91797 16.0165 5.91797 12.2665C5.91797 8.51654 9.13007 5.47656 13.0924 5.47656C17.0547 5.47656 20.2668 8.51654 20.2668 12.2665ZM8.70627 12.2665C8.70627 14.5591 10.67 16.4176 13.0924 16.4176C15.5148 16.4176 17.4785 14.5591 17.4785 12.2665C17.4785 9.97396 15.5148 8.11545 13.0924 8.11545C10.67 8.11545 8.70627 9.97396 8.70627 12.2665Z" fill="#5296CA"/>
    </svg>
    </g>
    
  </g>
`,
];
