const TOKEN_KEY = 'access_token';
const USER_INSTANCE = 'user_instance';
const REFRESHER_TOKEN_KEY = 'refresh_token';
const OTP_HASH = 'otp_hash';

/**
 * Manage how the access tokens are being stored and retreived from storage
 * 
 * Current implementation stores to Localstorage should always be 
 * accessed thorugh the instance. 
 */

const TokenService = {
  getToken(){
    return localStorage.getItem(TOKEN_KEY)
  },
  saveToken(accessToken){
    localStorage.setItem(TOKEN_KEY, accessToken)
  },
  removeToken(){
    localStorage.removeItem(TOKEN_KEY)
  },
  getOtpHash(){
        return localStorage.getItem(OTP_HASH);
  },
    saveOtpHash(otpHash){
        localStorage.setItem(OTP_HASH,otpHash);
    },
    removeOtpHash(){
        localStorage.removeItem(OTP_HASH);
    },
  getUser(){
    return localStorage.getItem(USER_INSTANCE)
  },
  saveUser(userInstance){
    localStorage.setItem(USER_INSTANCE, userInstance)
  },
  removeUser(){
    localStorage.removeItem(USER_INSTANCE)
  },
  
  saveRefreshToken(refresh_token){
    localStorage.setItem(REFRESHER_TOKEN_KEY, refresh_token)
  },
  getRefreshToken(){
    return localStorage.getItem(REFRESHER_TOKEN_KEY)
  },
  removeRefreshToken(){
    localStorage.removeItem(REFRESHER_TOKEN_KEY)
  }
}

export { TokenService }