import Vue from "vue";
import Router from "vue-router";
import store from "../store";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");
const Login = () => import("@/views/auth/Login");
const ForgotPassword = () => import("@/views/auth/ForgotPassword");
const ChangePassword = () => import("@/views/auth/ChangePassword");
const Transactions = () => import("@/views/Transactions");
const Settings = () => import("@/views/Settings");
const Report = () => import("@/views/Report");
// const Profile = () => import("@/views/Profile");

//send money
const SendToBank = () => import("@/views/send-money/SendToBank");
const ProcessTransaction = () =>
  import("@/views/send-money/ProcessTransaction");
const Terminals = () => import("@/views/Terminals");
const BecomeAnAgent = () => import("@/views/BecomeAnAgent");

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
  // mode: "history",
});

function configRoutes() {
  return [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ForgotPassword,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/change-password",
      name: "ChangePassword",
      component: ChangePassword,
      meta: {
        requiresGuest: true,
      },
    },

    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,

      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transactions",
          name: "Transactions",
          component: Transactions,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "become-an-agent",
          name: "BecomeAnAgent",
          component: BecomeAnAgent,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "report",
          name: "Report",
          component: Report,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "terminals",
          name: "Terminals",
          component: Terminals,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "settings",
          name: "Settings",
          component: Settings,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transfer-send-money",
          name: "SendToBank",
          component: SendToBank,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "process-transaction",
          name: "ProcessTransaction",
          component: ProcessTransaction,
          meta: {
            requiresAuth: true,
          },
        },
        // {
        //   path: "profile",
        //   name: "Profile",
        //   component: Profile,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
      ],
    },
  ];
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      //redirect to login page
      next("/login");
    } else if (store.getters.userObj.pin_setup === "no") {
      // console.log("rr");

      next("/settings");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (store.getters.isLoggedIn) {
      //redirect to the profile page
      next("/dashboard");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
